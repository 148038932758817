.settings-container {
  padding: 20px;
  max-width: 600px;
  margin: 0 auto;
}

.setting-item {
  margin: 20px 0;
  display: flex;
  align-items: center;
  gap: 10px;
}

.theme-select {
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 16px;
  min-width: 150px;
}

label {
  font-weight: 500;
}