/* CSS FONTS */
@import url('https://fonts.googleapis.com/css2?family=Kumbh+Sans:wght@100..900&display=swap');

/* CSS THEMES */
:root {
  --primary-color: #007eef;
  --secondary-color: #01539C;
  --font-color: #424242;
  --bg-color: #F7FAFC;
  --heading-color: #292922;
  --border-color: #808080;
  --button-color1: #95cdfe;
  --button-color2: #e3f2ff;
  --button-color3: #70bcff;
  --button-font-color: #000000;
  --filter: none;
} 

[data-theme="dark"] {
  --primary-color: #9A97F3;
  --secondary-color: #818cab;
  --font-color: #e1e1ff;
  --bg-color: #161625;
  --heading-color: #ffffff;
  --border-color: #9a9a9a;
  --button-color1: #b3b2f7;
  --button-color2: #d1d0fa;
  --button-color3: #a1a0f5;
  --button-font-color: #000000;
  --filter: invert(1);
}

[data-theme="secondary"] {
  --primary-color: #0B3D91;
  --secondary-color: #083075;
  --font-color: #0B3D91;
  --bg-color: #F7F7F7;
  --heading-color: #C8102E;
  --border-color: #0B3D91;
  --button-color1: #4A8CFF;
  --button-color2: #6699FF;
  --button-color3: #3366FF;
  --button-font-color: #ffffff;
  --filter: none;
}

[data-theme="spring"] {
  --primary-color: #66CDAA;
  --secondary-color: #3CB371;
  --font-color: #2E8B57;
  --bg-color: #F5FFFA;
  --heading-color: #2E8B57;
  --border-color: #66CDAA;
  --button-color1: #98FB98;
  --button-color2: #90EE90;
  --button-color3: #8FBC8F;
  --button-font-color: #000000;
  --filter: none;
}

[data-theme="summer"] {
  --primary-color: #FFD700;
  --secondary-color: #FFA500;
  --font-color: #FF4500;
  --bg-color: #FFFACD;
  --heading-color: #FF6347;
  --border-color: #FFD700;
  --button-color1: #FFEC8B;
  --button-color2: #FFF68F;
  --button-color3: #FFD700;
  --button-font-color: #000000;
  --filter: none;
}

[data-theme="fall"] {
  --primary-color: #D2691E;
  --secondary-color: #8B4513;
  --font-color: #4B2E0F;
  --bg-color: #FFF8DC;
  --heading-color: #8B4513;
  --border-color: #D2691E;
  --button-color1: #ffa500;
  --button-color2: #ff8c00;
  --button-color3: #ff7f50;
  --button-font-color: #000000;
  --filter: none;
}

[data-theme="winter"] {
  --primary-color: #5A9BD4;
  --secondary-color: #7FB3C8;
  --font-color: #3999ed;
  --bg-color: #e3f1ff;
  --heading-color: #4A90E2;
  --border-color: #5A9BD4;
  --button-color1: #b3d7f7;
  --button-color2: #c7e2ff;
  --button-color3: #a9c9ff;
  --button-font-color: #000000;
  --filter: none;
}

[data-theme="deuteranopia"] {
  --primary-color: #0077BB;
  --secondary-color: #004466;
  --font-color: #000000;
  --bg-color: #FFFFFF;
  --heading-color: #EE7733;
  --border-color: #0077BB;
  --button-color1: #009988;
  --button-color2: #33BBEE;
  --button-color3: #EE7733;
  --button-font-color: #FFFFFF;
  --filter: url('#deuteranopia-filter');
}

[data-theme="protanopia"] {
  --primary-color: #0066FF;
  --secondary-color: #003399;
  --font-color: #000000;
  --bg-color: #FFFFFF;
  --heading-color: #FFBB33;
  --border-color: #0066FF;
  --button-color1: #00CC99;
  --button-color2: #33BBFF;
  --button-color3: #FFBB33;
  --button-font-color: #FFFFFF;
  --filter: url('#protanopia-filter');
}

[data-theme="tritanopia"] {
  --primary-color: #CC3311;
  --secondary-color: #882211;
  --font-color: #000000;
  --bg-color: #FFFFFF;
  --heading-color: #EE3377;
  --border-color: #CC3311;
  --button-color1: #009988;
  --button-color2: #882255;
  --button-color3: #EE3377;
  --button-font-color: #FFFFFF;
  --filter: url('#tritanopia-filter');
}


[data-theme="monochrome"] {
  --primary-color: #404040;
  --secondary-color: #595959;
  --font-color: #000000;
  --bg-color: #FFFFFF;
  --heading-color: #262626;
  --border-color: #404040;
  --button-color1: #737373;
  --button-color2: #595959;
  --button-color3: #404040;
  --button-font-color: #FFFFFF;
  --filter: grayscale(100%) contrast(120%);
}

/* CSS DOCUMENTATION */
body {
  background-color: var(--bg-color);
  padding-top: 4em;
}


#map {
  filter: var(--filter);
}

#title {
  text-align: center;
  font-family: "Kumbh Sans", sans-serif;
  font-size: 2em;
  color: var(--heading-color);
}

.line {
  stroke: var(--border-color);
  stroke-width: 4;
  fill: none;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.selected {
  stroke: var(--primary-color);
  stroke-width: 8;
}

.maskedselected {
  stroke-width: 100;
  stroke: white;
}

#startpt {
  fill: #FF0000;
  stroke: var(--secondary-color);
  stroke-width: 0.2em;
}

#endpt {
  fill: #00FF00;
  stroke: var(--secondary-color);
  stroke-width: 0.2em;
}
#agent {
  fill: rgb(255, 0, 0);
  stroke: var(--secondary-color);
  stroke-width: 0.2em;
}

#svgdiv {
  width: 96vmin;
  margin: auto;
}

svg {
  width: 96vmin;
  height: 96vmin;
  display: block;
  margin: auto;
  border: var(--border-color) solid 2px;
  border-radius: 1em;
}

#inputdiv {
  display: flex;
  justify-content: center;
  margin-bottom: 1em;
  flex-wrap: wrap;
  gap: 1em;
}

#inputdiv input {
  padding: 0.25em;
}

html {
  overflow-x: hidden;
}

#scroll {
  overflow: auto;
  position: absolute;
  height: 0px;
  width: 0px;
  padding: 48vmin;
}

#scroll::-webkit-scrollbar {
  width: 0px;
}

#stairwell {
  fill: #FFFF00;
  stroke: #740000;
  stroke-width: 0.2em;
}

#progbar {
  width: 45%;
}

#progbarcontainer{
  width: 100%;
  display: flex;
  justify-content: center;
}

#schedulecontainer {
  display: flex;
  justify-content: center;
  margin: 1em;
  gap: 1em;
}

.textbox {
  border: solid var(--border-color) 2px;
  border-radius: 1em;
  background-color: var(--bg-color);
  color: var(--font-color);
}

#btn {
  border: solid var(--border-color) 0.15em;
  border-radius: 0.5em;
  background-color: var(--button-color1);
  color: var(--button-font-color);
  transition: background-color 0.1s;
}

#btn:hover {
  background-color: var(--button-color2);
  border: solid var(--border-color) 0.15em;
}

#btn:active {
  background-color: var(--button-color3);
  border: solid var(--border-color) 2px;
}

/* Add SVG filters to HTML */